<template>
  <div class="container">
    <v-navigation-drawer app class="ma-0 pa-0" v-model="mostrar">
      <opciones />
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="mostrar = !mostrar"></v-app-bar-nav-icon>
      <h3>LISTADO DE CASILLEROS</h3>
      <v-flex text-right>
        <v-btn color="success" to="/nuevo_casillero" class="ml-auto" small>
          NUEVA
        </v-btn>
      </v-flex>
    </v-app-bar>
    <v-container elevation="10" fluid>
      <ListadoCasilleroComponent />
    </v-container>
  </div>
</template>

<script>
export default {
  name: "listadoCasillero",
  components: {
    opciones: () => import("@/components/comun/opciones.vue"),
    ListadoCasilleroComponent: () =>
      import("@/components/casillero/listadoCasilleroComponent.vue"),
  },
  data() {
    return {
      mostrar: true,
    };
  },
};
</script>

<style></style>
